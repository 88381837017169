a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.githubImg {
    margin-top: 6px;
    margin-left: 6px;
}

@media (max-width: 576px) {
    .githubImg {
        margin-left: 0;
    }
}

@media (min-width: 768px) {
    .githubImg {
        margin-top: 10px;
    }
}
