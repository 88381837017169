.blogHeader {
    margin-top: 3.0rem;
}

.tags {
    margin-top: 1.75rem;
    margin-bottom: 0.55555rem;
}

    .tags .tag {
        background: #f8f8f8;
        box-shadow: none;
        padding: 4px 7px;
        margin-right: 0.55555rem;
    }

    .tags a {
        color: #007acc;
        text-decoration: none;
        display: inline-block;
    }

.footerNav {
    list-style: none;
    padding-left: 0;
    margin-bottom: 4.3575rem;
}

    .footerNav li {
        display: list-item;
    }

    .footerNav .footerNavLink {
        text-decoration: underline;
        color: #007acc;
        margin-bottom: calc(1.75rem / 2);
        padding-left: 0;
    }

        .footerNav .footerNavLink:hover,
        .footerNav .footerNavLink:active {
            text-decoration: none;
        }
