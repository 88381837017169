@import url('https://fonts.googleapis.com/css?family=Montserrat:700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Merriweather&display=swap');

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
    font-family: 'Merriweather', Georgia, serif;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 2.5rem;
}

small {
    line-height: 1.75rem;
    font-size: 0.8rem;
}

small, p {
    font-family: 'Merriweather', Georgia, serif;
}

p {
    font-size: 0.9rem;
}

.img-max-size {
    max-width: 100%;
}
