/*
    Blog and docs content
*/
.contentSection {
    font-family: 'Merriweather', Georgia, serif;
    font-size: 0.88888rem;
}

    .contentSection img {
        margin-bottom: 1.75rem;
    }

    .contentSection code {
        padding: .1em;
        border-radius: .3rem;
        background-color: #fdf6e3;
        color: #657b83;
        font-family: Consolas,Monaco,Andale monospace,monospace;
        font-size: 1em;
        line-height: 1.5rem;
    }

    .contentSection ul, ol {
        padding-left: 0;
    }

    .contentSection li {
        line-height: 1.55555rem;
        color: rgba(0,0,0,0.9);
    }

    .contentSection p {
        margin-bottom: 1.75rem;
        font-size:0.9rem;
        line-height: 1.55555rem;
    }

    .contentSection a {
        text-decoration: underline;
    }

        .contentSection a:hover,
        .contentSection a:active {
            text-decoration: none;
        }

/*
    https://www.pvladov.com/p/syntax-highlighter.html?m=1
*/
pre.cscode {
    margin: 0em;
    overflow: auto;
    background-color: rgb(244, 244, 249);
    border: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 1.5rem;
}

.cscode code {
    background-color: rgb(244, 244, 249);
    font-family: Consolas,"Courier New",Courier,Monospace;
    font-size: 10pt;
    color: #000000;
}

@media (max-width: 768px) {
    .cscode code {
        font-size: 8pt;
    }
}

.cscode .key {
    color: #0000ff;
}

.cscode .com {
    color: #008000;
}

.cscode .str {
    color: #a31515;
}

/* Blog and ForgeDocs shared styles */
.topicDiv {
    margin-top: 3.0rem;
}

.topicHeader {
    margin-bottom: 0.4375rem;
}

.topicNavLink {
    color: #007acc;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.topicSummary {
    font-family: 'Merriweather', Georgia, serif;
    font-size: 1rem;
    line-height: 1.55555rem;
}

.hLine {
    background: rgba(0,0,0,0.2);
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
}

.footerBox {
    display: flex;
    margin-bottom: 4.3575rem;
}

.footerLeft {
    min-width: 50px;
    min-height: 50px;
    margin-right: 0.875rem;
    margin-bottom: 0;
    overflow: hidden;
}

.footerImg {
    width: 50px;
    height: auto;
    border-radius: 100%;
}

.maxWidth {
    max-width: 100%;
    width: 100%;
}
